import { useEffect, useMemo, useState } from 'react';
import { Box, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel, GridSortItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';

import {
  AgencyApprovalStatus,
  AgencyListApprovalsApprovalList,
  ApprovalQuerySortProperty,
  ModelsApprovalModel,
  ModelsOrderByDirection,
  ModelsFundraisingEventModel as FundraisingEvent,
  useListApprovals,
} from '../../api/agency-api';
import { useDebouncedValue } from '../../shared/utils';
import { TextInput } from '../../shared/forms';
import { DataGridToolbar } from '../../shared/components';
import { AgencyApprovalModal } from '../../admin/components/AgencyApprovalModal';

interface ApprovalHistoryProps {
  agencyId: string;
  nextEvent?: FundraisingEvent | null;
  events: FundraisingEvent[];
  isActive: boolean;
  isAdmin?: boolean;
}

interface ApprovalFilterForm {
  search?: string;
  eventId?: string;
  approvalStatus?: AgencyApprovalStatus;
  pagination: GridPaginationModel;
  orderBy: GridSortItem[];
}

export default function AgencyApprovalHistory({
  agencyId,
  nextEvent,
  events,
  isActive,
  isAdmin,
}: ApprovalHistoryProps) {
  const [gridData, setGridData] = useState<AgencyListApprovalsApprovalList>();

  const form = useForm<ApprovalFilterForm>({
    defaultValues: {
      eventId: nextEvent?.id,
      pagination: { page: 0, pageSize: 10 },
      orderBy: [{ field: 'createdAt', sort: 'desc' }],
    },
  });

  const { search, eventId, approvalStatus, pagination, orderBy } = form.watch();
  const params = useDebouncedValue(
    () => ({
      search,
      eventId,
      approvalStatus,
      orderBy: !!orderBy.length ? sortFieldMap.get(orderBy[0].field) : undefined,
      orderByDirection: !!orderBy.length
        ? orderBy[0].sort === 'desc'
          ? ModelsOrderByDirection.Descending
          : ModelsOrderByDirection.Ascending
        : undefined,
      ...pagination,
    }),
    500,
    [search, eventId, approvalStatus, pagination, orderBy]
  );

  const { data, isPending } = useListApprovals(agencyId, params);

  useEffect(() => {
    if (!isPending) setGridData(data);
  }, [data, isPending]);

  const filterFields = useMemo(
    () => (
      <>
        <Grid item xs={12} sm={4} md={3}>
          <TextInput select name="eventId" label="Event" variant="outlined">
            <MenuItem value="">All Events</MenuItem>
            {events.map(x => (
              <MenuItem key={x.id} value={x.id}>
                {x.name} - {x.eventDate}
              </MenuItem>
            ))}
          </TextInput>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextInput name="approvalStatus" label="Status" select variant="outlined">
            <MenuItem value="">&nbsp;</MenuItem>
            {Object.values(AgencyApprovalStatus).map(x => (
              <MenuItem key={x} value={x}>
                {x}
              </MenuItem>
            ))}
          </TextInput>
        </Grid>
      </>
    ),
    [events]
  );

  return (
    <Box>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'start', sm: 'center' }}
        mb={2}
      >
        <Typography variant="h4" component="h2" color="primary.main">
          Approval Log
        </Typography>
        {isAdmin && isActive && !!nextEvent && (
          <AgencyApprovalModal agencyId={agencyId} nextEvent={nextEvent} />
        )}
      </Stack>

      <FormProvider {...form}>
        {gridData && (
          <DataGrid
            rows={gridData?.items ?? []}
            rowCount={gridData?.totalCount}
            columns={columns}
            autoHeight
            pagination
            paginationMode="server"
            pageSizeOptions={[5, 10, 25, 50]}
            paginationModel={pagination}
            onPaginationModelChange={x => form.setValue('pagination', x)}
            sortingMode="server"
            sortModel={orderBy}
            onSortModelChange={x => form.setValue('orderBy', x)}
            slots={{
              toolbar: DataGridToolbar,
            }}
            slotProps={{
              toolbar: {
                filterFields: filterFields,
                modelName: 'Approval Log',
                allColumns: true,
              },
            }}
            sx={{ width: '100%', border: '0', '& .MuiDataGrid-toolbarContainer': { gap: '0px' } }}
          />
        )}
      </FormProvider>
    </Box>
  );
}
const sortFieldMap = new Map<string, ApprovalQuerySortProperty>([
  ['createdAt', ApprovalQuerySortProperty.CreatedAt],
]);
const columns: GridColDef<ModelsApprovalModel>[] = [
  {
    field: 'createdAt',
    headerName: 'Timestamp',
    width: 250,
    valueGetter: ({ row }) => dayjs(row.createdAt).format('YYYY/MM/DD h:mm a'),
  },
  {
    field: 'eventName',
    headerName: 'Event',
    width: 200,
  },
  {
    field: 'approvalStatus',
    headerName: 'Status',
    width: 200,
  },
  {
    field: 'emailAddress',
    headerName: 'User',
    width: 200,
  },
  {
    field: 'comment',
    headerName: 'Comments',
    width: 200,
  },
];

import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Tooltip,
  Typography,
  Grid,
  Collapse,
  styled,
  MenuItem,
} from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { DataGrid, GridColDef, GridPaginationModel, GridSortItem } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { icons } from '../../shared/icons';
import {
  ModelsOrderByDirection,
  UserQuerySortProperty,
  UserRole,
  UsersGetUsersUserList,
  exportUsers,
  useGetUsers,
} from '../../api/agency-api';
import { useDebouncedValue } from '../../shared/utils';
import { useNavigate } from 'react-router-dom';
import { formatName } from '../../shared/utils/formatName';
import downloadFile from '../../shared/utils/downloadFile';
import { FormProvider, useForm } from 'react-hook-form';
import { TextInput } from '../../shared/forms';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: 'common.white',
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface UserFiltersForm {
  search?: string;
  userRole?: UserRole;

  pagination: GridPaginationModel;
  orderBy: GridSortItem[];
}

const sortFieldMap = new Map<string, UserQuerySortProperty>([
  ['name', UserQuerySortProperty.Name],
  ['id', UserQuerySortProperty.Id],
  ['email', UserQuerySortProperty.Email],
]);

export const UserList: React.FC = () => {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      editable: true,
      renderCell: params => (
        <Button
          color="primary"
          onClick={() => {
            navigate(`/admin/users/${params.value}`);
          }}
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: true,
      valueGetter: params => formatName(params.row.name),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: true,
    },
    {
      field: 'agencyDisplayName',
      headerName: 'Agency',
      flex: 1,
      editable: true,
      valueGetter: params => params.row.agencyDisplayName ?? 'N/A',
    },
    {
      field: 'role',
      headerName: 'User Role',
      flex: 1,
      editable: true,
    },
  ];
  const [expanded, setExpanded] = React.useState(false);

  const form = useForm<UserFiltersForm>({
    defaultValues: {
      pagination: { page: 0, pageSize: 10 },
      orderBy: [{ field: 'name', sort: 'asc' }],
    },
  });

  const { search, userRole, pagination, orderBy } = form.watch();
  const params = useDebouncedValue(
    () => ({
      search,
      userRole,
      orderBy: !!orderBy.length ? sortFieldMap.get(orderBy[0].field) : undefined,
      orderByDirection: !!orderBy.length
        ? orderBy[0].sort === 'desc'
          ? ModelsOrderByDirection.Descending
          : ModelsOrderByDirection.Ascending
        : undefined,
      ...pagination,
    }),
    500,
    [search, userRole, pagination, orderBy]
  );
  const { data, isPending } = useGetUsers(params);

  const [gridData, setGridData] = useState<UsersGetUsersUserList>();

  useEffect(() => {
    if (!isPending) setGridData(data);
  }, [data, isPending]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <FormProvider {...form}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: 'grey.200' }}
      >
        <Stack direction="row" px={1} alignItems="center">
          <TextInput
            name="search"
            variant="outlined"
            placeholder="Search"
            sx={{ marginBottom: '0' }}
          />
          <Typography
            mb={0}
            ml={1}
            color="grey.600"
            variant="body2"
            display={{ xs: 'none', sm: 'flex' }}
          >
            FILTERS
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{ color: 'grey.600' }}
          >
            <FontAwesomeIcon icon={icons.chevronDown} color="grey.100" />
          </ExpandMore>
        </Stack>

        <Stack direction="row" sx={{ backgroundColor: 'grey.600' }}>
          <Tooltip title="New User" arrow placement="bottom-start">
            <Button
              sx={{
                color: 'common.white',
                padding: '1rem',
                borderRight: 'solid thin',
                borderColor: 'grey.100',
              }}
              onClick={() => {
                navigate('/admin/users/add');
              }}
            >
              <FontAwesomeIcon icon={icons.add} size="2x" />
            </Button>
          </Tooltip>
          <Tooltip title="Export User List" arrow placement="bottom">
            <Button
              sx={{
                color: 'common.white',
                padding: '1rem',
                borderRight: 'solid thin',
                borderColor: 'primary.main',
              }}
              onClick={() =>
                downloadFile(
                  'User-Export.csv',
                  exportUsers({ ...params, pageSize: undefined, page: undefined })
                )
              }
            >
              <FontAwesomeIcon icon={icons.download} size="2x" />
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ backgroundColor: 'grey.100' }}>
        <Grid container spacing={2} p={4}>
          <Grid item xs={12} sm={4} md={3}>
            <TextInput name="userRole" label="User Role" variant="outlined" select>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.values(UserRole).map(x => (
                <MenuItem key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </TextInput>
          </Grid>
        </Grid>
      </Collapse>
      {gridData && (
        <DataGrid
          rows={gridData?.items ?? []}
          rowCount={gridData?.totalCount}
          columns={columns}
          pagination
          paginationMode="server"
          paginationModel={pagination}
          pageSizeOptions={[5, 10, 25, 50]}
          onPaginationModelChange={x => form.setValue('pagination', x)}
          sortingMode="server"
          sortModel={orderBy}
          onSortModelChange={x => form.setValue('orderBy', x)}
          sx={{ width: '100%', border: '0' }}
          autoHeight
        />
      )}
    </FormProvider>
  );
};

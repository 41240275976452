import { RelatedEntity, File, RichTextContent, GrapesContent, EntityItem } from './common';

export interface Positioned {
  horizontalPosition: HorizontalPosition;
  verticalPosition: VerticalPosition;
}

export interface BannerLogoComponent extends Positioned {
  id: number;
  image: RelatedEntity<File>;
}

export interface ReviewQuoteComponent {
  id: number;
  quote: string;
  attribution: string;
  backgroundImage: RelatedEntity<File>;
}

export interface LearnMoreInfoComponent {
  id: number;
  name: string;
  infoLink: string;
  type: MediaType;
}

export interface ScholarshipComponent {
  id: number;
  name: string;
  description: string;
}

export interface SponsorsComponent {
  id: number;
  sponsorLink: string;
  logo: RelatedEntity<File>;
}

export interface LeadershipComponent {
  id: number;
  name: string;
  title: string;
  organization: string;
}

export interface AlumniComponent {
  id: number;
  firstName: string;
  lastName: string;
}

export interface FlipCardComponent {
  id: number;
  title: string;
  description: string;
  link: string;
  linkCopy: string;
  cardWidth: string;
  flipImage: RelatedEntity<File>;
  frontLogo: RelatedEntity<File>;
}

export interface NewsFlipCardComponent {
  id: number;
  date: string;
  title: string;
  description: string;
  flipImage: RelatedEntity<File>;
}

export interface VerticalTabComponent {
  id: number;
  title: string;
  copy: RichTextContent;
}

export interface BannerComponent {
  id: number;
  title: string;
  pageBanner: RelatedEntity<File>;
  tabletBanner: RelatedEntity<File>;
  mobileBanner: RelatedEntity<File>;
  bannerLogo: BannerLogoComponent;
  bannerText: BannerTextComponent;
}

export interface BannerTextComponent extends Positioned {
  id: number;
  text: RichTextContent;
}

export interface StatComponent {
  id: number;
  stat: number;
  statCopy: RichTextContent;
  statDecimals?: number;
  statPrefix?: string;
  statSuffix?: string;
  statVariant?: string;
}

export interface StatSectionComponent {
  backgroundColor: string;
  sectionCopy: RichTextContent;
  stats: Array<StatComponent>;
}

export interface BannerLinkComponent extends Positioned {
  linkText: string;
  linkUrl: string;
  linkSource: LinkSource;
}

export interface PageFooterComponent {
  id: number;
  page_footer: EntityItem<PageFooter>;
}

export interface PageFooter {
  title: string;
  content: GrapesContent;
}

export enum HorizontalPosition {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export enum VerticalPosition {
  Top = 'Top',
  Center = 'Center',
  Bottom = 'Bottom',
}

export enum LinkSource {
  Internal = 'internal',
  External = 'external',
}

export enum MediaType {
  Email = 'Email',
  Web = 'Web',
  Facebook = 'Facebook',
}

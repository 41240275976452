import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, MenuItem } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel, GridSortItem } from '@mui/x-data-grid';
import {
  FundraisingEventsListEventsFundraisingEventList as EventListModel,
  FundraisingEventQuerySortProperty,
  ModelsFundraisingEventModel,
  ModelsOrderByDirection,
  useListEvents,
} from '../../api/agency-api';

import { useDebouncedValue } from '../../shared/utils';
import { DataGridToolbar } from '../../shared/components';
import { TextInput } from '../../shared/forms';

import { AddEventModal } from './AddEventModal';
import { EditEventModal } from './EditEventModal';

interface EventFilterForm {
  search?: string;
  name?: string;
  includePastEvents: boolean;
  eventDate?: string;
  registrationEnabled?: boolean;

  pagination: GridPaginationModel;
  orderBy: GridSortItem[];
}

const sortFieldMap = new Map<string, FundraisingEventQuerySortProperty>([
  ['eventDate', FundraisingEventQuerySortProperty.EventDate],
  ['name', FundraisingEventQuerySortProperty.Name],
  ['id', FundraisingEventQuerySortProperty.Id],
]);

export const FundraisingEventList: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const handleAddOpen = () => setOpen(true);
  const handleAddClose = () => setOpen(false);
  const handleEditInit = useCallback(
    (eventId: string, isOpen: boolean) => {
      setSelectedId(eventId);
      setEditOpen(isOpen);
    },
    [setSelectedId, setEditOpen]
  );

  const handleEditOpen = useCallback(
    (eventId: string) => {
      handleEditInit(eventId, true);
    },
    [handleEditInit]
  );
  const handleEditClose = useCallback(() => {
    handleEditInit('', false);
  }, [handleEditInit]);
  const form = useForm<EventFilterForm>({
    defaultValues: {
      includePastEvents: true,
      pagination: { page: 0, pageSize: 10 },
      orderBy: [{ field: 'eventDate', sort: 'desc' }],
    },
  });

  const { search, name, includePastEvents, eventDate, registrationEnabled, pagination, orderBy } =
    form.watch();

  const params = useDebouncedValue(
    () => ({
      search,
      name,
      includePastEvents,
      eventDate,
      registrationEnabled,
      orderBy: !!orderBy.length ? sortFieldMap.get(orderBy[0].field) : undefined,
      orderByDirection: !!orderBy.length
        ? orderBy[0].sort === 'desc'
          ? ModelsOrderByDirection.Descending
          : ModelsOrderByDirection.Ascending
        : undefined,
      ...pagination,
    }),
    500,
    [search, name, includePastEvents, eventDate, registrationEnabled, pagination, orderBy]
  );
  const [gridData, setGridData] = useState<EventListModel>();
  const { data, isPending } = useListEvents(params);

  // only update the gridData after the server responds so that the list doesn't flicker.
  useEffect(() => {
    if (!isPending) setGridData(data);
  }, [data, isPending]);

  return (
    <>
      <FormProvider {...form}>
        {gridData && (
          <DataGrid
            autoHeight
            rows={gridData?.items ?? []}
            rowCount={gridData?.totalCount}
            columns={columns}
            pagination
            paginationMode="server"
            paginationModel={pagination}
            pageSizeOptions={[5, 10, 25, 50]}
            onPaginationModelChange={x => form.setValue('pagination', x)}
            sortingMode="server"
            sortModel={orderBy}
            onSortModelChange={x => form.setValue('orderBy', x)}
            slots={{
              toolbar: DataGridToolbar,
            }}
            slotProps={{
              toolbar: {
                filterFields: filterFields,
                modelName: 'Event',
                allColumns: true,
                onAdd: handleAddOpen,
              },
            }}
            onRowClick={({ row }) => {
              handleEditOpen(row.id);
            }}
            sx={{ width: '100%', border: '0', '& .MuiDataGrid-toolbarContainer': { gap: '0px' } }}
          />
        )}
      </FormProvider>
      <AddEventModal open={open} onClose={handleAddClose} />
      <EditEventModal
        open={editOpen}
        onClose={handleEditClose}
        eventId={selectedId}
      ></EditEventModal>
    </>
  );
};

const columns: GridColDef<ModelsFundraisingEventModel>[] = [
  {
    field: 'name',
    headerName: 'Event Name',
    flex: 1,
  },
  {
    field: 'eventDate',
    headerName: 'Event Date',
    flex: 1,
  },
  {
    field: 'registrationEnabled',
    headerName: 'Registration Enabled',
    flex: 1,
  },
];

const filterFields = (
  <>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput name="name" label="Event Name" variant="outlined" />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput name="registrationEnabled" label="Registration Enabled" select variant="outlined">
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="false">No</MenuItem>
        <MenuItem value="true">Yes</MenuItem>
      </TextInput>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput name="includePastEvents" label="Include Past Events" select variant="outlined">
        <MenuItem value="false">No</MenuItem>
        <MenuItem value="true">Yes</MenuItem>
      </TextInput>
    </Grid>
  </>
);

/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  ExportUsersParams,
  GetUsersParams,
  UsersAddUserForm,
  UsersAddUserResult,
  UsersDisableUserResult,
  UsersEditUserResult,
  UsersEnableUserResult,
  UsersGetMyUserResult,
  UsersGetUserByIdResult,
  UsersGetUsersUserList,
  UsersResetPasswordResult,
} from '.././models';
import { apiRequest } from '.././client';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getUsers = (
  params?: GetUsersParams,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<UsersGetUsersUserList>(
    { url: `/Users`, method: 'GET', params, signal },
    options
  );
};

export const getGetUsersQueryKey = (params?: GetUsersParams) => {
  return [`/Users`, ...(params ? [params] : [])] as const;
};

export const getGetUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = unknown
>(
  params?: GetUsersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) =>
    getUsers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>;
export type GetUsersQueryError = unknown;

export const useGetUsers = <TData = Awaited<ReturnType<typeof getUsers>>, TError = unknown>(
  params?: GetUsersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const addUser = (
  usersAddUserForm: UsersAddUserForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<UsersAddUserResult>(
    {
      url: `/Users`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: usersAddUserForm,
    },
    options
  );
};

export const getAddUserMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUser>>,
    TError,
    { data: UsersAddUserForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addUser>>,
  TError,
  { data: UsersAddUserForm },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addUser>>,
    { data: UsersAddUserForm }
  > = props => {
    const { data } = props ?? {};

    return addUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddUserMutationResult = NonNullable<Awaited<ReturnType<typeof addUser>>>;
export type AddUserMutationBody = UsersAddUserForm;
export type AddUserMutationError = unknown;

export const useAddUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUser>>,
    TError,
    { data: UsersAddUserForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addUser>>,
  TError,
  { data: UsersAddUserForm },
  TContext
> => {
  const mutationOptions = getAddUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getMyUser = (options?: SecondParameter<typeof apiRequest>, signal?: AbortSignal) => {
  return apiRequest<UsersGetMyUserResult>({ url: `/Users/me`, method: 'GET', signal }, options);
};

export const getGetMyUserQueryKey = () => {
  return [`/Users/me`] as const;
};

export const getGetMyUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getMyUser>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMyUser>>, TError, TData>>;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMyUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyUser>>> = ({ signal }) =>
    getMyUser(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMyUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMyUserQueryResult = NonNullable<Awaited<ReturnType<typeof getMyUser>>>;
export type GetMyUserQueryError = unknown;

export const useGetMyUser = <
  TData = Awaited<ReturnType<typeof getMyUser>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMyUser>>, TError, TData>>;
  request?: SecondParameter<typeof apiRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMyUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getById = (
  id: string,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<UsersGetUserByIdResult>(
    { url: `/Users/${id}`, method: 'GET', signal },
    options
  );
};

export const getGetByIdQueryKey = (id: string) => {
  return [`/Users/${id}`] as const;
};

export const getGetByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getById>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getById>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getById>>> = ({ signal }) =>
    getById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getById>>>;
export type GetByIdQueryError = unknown;

export const useGetById = <TData = Awaited<ReturnType<typeof getById>>, TError = unknown>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getById>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const editUser = (
  id: string,
  usersAddUserForm: UsersAddUserForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<UsersEditUserResult>(
    {
      url: `/Users/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: usersAddUserForm,
    },
    options
  );
};

export const getEditUserMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editUser>>,
    TError,
    { id: string; data: UsersAddUserForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof editUser>>,
  TError,
  { id: string; data: UsersAddUserForm },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editUser>>,
    { id: string; data: UsersAddUserForm }
  > = props => {
    const { id, data } = props ?? {};

    return editUser(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditUserMutationResult = NonNullable<Awaited<ReturnType<typeof editUser>>>;
export type EditUserMutationBody = UsersAddUserForm;
export type EditUserMutationError = unknown;

export const useEditUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editUser>>,
    TError,
    { id: string; data: UsersAddUserForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof editUser>>,
  TError,
  { id: string; data: UsersAddUserForm },
  TContext
> => {
  const mutationOptions = getEditUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const resetPassword = (id: string, options?: SecondParameter<typeof apiRequest>) => {
  return apiRequest<UsersResetPasswordResult>(
    { url: `/Users/${id}/ResetPassword`, method: 'POST' },
    options
  );
};

export const getResetPasswordMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetPassword>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetPassword>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return resetPassword(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof resetPassword>>>;

export type ResetPasswordMutationError = unknown;

export const useResetPassword = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof resetPassword>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const disableUser = (id: string, options?: SecondParameter<typeof apiRequest>) => {
  return apiRequest<UsersDisableUserResult>(
    { url: `/Users/${id}/DisableUser`, method: 'PUT' },
    options
  );
};

export const getDisableUserMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof disableUser>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof disableUser>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof disableUser>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return disableUser(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DisableUserMutationResult = NonNullable<Awaited<ReturnType<typeof disableUser>>>;

export type DisableUserMutationError = unknown;

export const useDisableUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof disableUser>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof disableUser>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDisableUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const enableUser = (id: string, options?: SecondParameter<typeof apiRequest>) => {
  return apiRequest<UsersEnableUserResult>(
    { url: `/Users/${id}/EnableUser`, method: 'PUT' },
    options
  );
};

export const getEnableUserMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof enableUser>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof enableUser>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof enableUser>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return enableUser(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EnableUserMutationResult = NonNullable<Awaited<ReturnType<typeof enableUser>>>;

export type EnableUserMutationError = unknown;

export const useEnableUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof enableUser>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<Awaited<ReturnType<typeof enableUser>>, TError, { id: string }, TContext> => {
  const mutationOptions = getEnableUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const exportUsers = (
  params?: ExportUsersParams,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<Blob>(
    { url: `/Users/Export`, method: 'GET', params, responseType: 'blob', signal },
    options
  );
};

export const getExportUsersQueryKey = (params?: ExportUsersParams) => {
  return [`/Users/Export`, ...(params ? [params] : [])] as const;
};

export const getExportUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof exportUsers>>,
  TError = unknown
>(
  params?: ExportUsersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof exportUsers>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExportUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exportUsers>>> = ({ signal }) =>
    exportUsers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exportUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExportUsersQueryResult = NonNullable<Awaited<ReturnType<typeof exportUsers>>>;
export type ExportUsersQueryError = unknown;

export const useExportUsers = <TData = Awaited<ReturnType<typeof exportUsers>>, TError = unknown>(
  params?: ExportUsersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof exportUsers>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExportUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { NavigationItem, NavigationItemType } from '../../api/content';
import { GenericContent } from './GenericContent';

interface Props {
  item: NavigationItem;
}

export const NavigationRoute: React.FC<Props> = ({ item }) => {
  const pages = useMemo(
    () => item?.items.filter(x => x.type === NavigationItemType.Internal) ?? [],
    [item]
  );

  return (
    <Routes>
      <Route index element={<GenericContent item={item} />} />
      {pages.map(x => (
        <Route
          key={x.id}
          path={`${x.path?.split('/').at(-1)}/*`}
          element={<NavigationRoute item={x} />}
        />
      ))}
    </Routes>
  );
};

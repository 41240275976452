import { NavigationItem, NavigationItemType } from '../../api/content/models/navigation-item';

export class NavigationStructure {
  private readonly _itemLookup: Map<number, NavigationItem>;

  constructor(public readonly items: Array<NavigationItem>) {
    this._itemLookup = new Map<number, NavigationItem>(itemPairs(items));
  }

  getPath(path: string) {
    const parts = path.split('/').filter(x => !!x);

    let firstPath = `/${parts.shift()}`;
    let navItem = this.items.find(x => x.path === firstPath);

    while (parts.length) {
      const key = parts.shift();
      const nextPath = `${navItem?.path}/${key}`;
      navItem = navItem?.items?.find(x => x.path === nextPath);
    }

    return navItem;
  }

  getContentItems(path?: string | null) {
    const items = path != null ? this.getPath(path)?.items : this.items;

    return items?.filter(x => x.type === NavigationItemType.Internal) ?? [];
  }

  find(id: number) {
    return this._itemLookup.get(id);
  }

  getMenuItems() {
    return this.items.filter(x => x.enabled && x.menuAttached);
  }

  getSideMenuItems() {
    return this.items.filter(
      x => (x.enabled && x.sideMenuAttached) || x.items.some(y => y.enabled && y.sideMenuAttached)
    );
  }

  getFooterItems() {
    return this.items.filter(
      x => (x.enabled && x.footerAttached) || x.items.some(y => y.enabled && y.footerAttached)
    );
  }
}

function* itemPairs(items: Array<NavigationItem>): Generator<[number, NavigationItem]> {
  for (const item of items) {
    yield [item.id, item];

    if (item.items != null) yield* itemPairs(item.items);
  }
}

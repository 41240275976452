import { Navigate, useParams } from 'react-router-dom';
import { EditAgencyForm } from '../../agency/components/EditAgencyForm';

export default function AdminAgencyDetailsPage() {
  const { agencyId: agencyIdParam } = useParams<{ agencyId: string }>();
  const agencyId = agencyIdParam ?? '';
  return (
    <>{agencyId ? <EditAgencyForm isAdmin={true} agencyId={agencyId} /> : <Navigate to="/" />}</>
  );
}

import * as yup from 'yup';

const contactSchema = yup.object({
  title: yup.string().nullable(),
  name: yup.object({
    first: yup.string().label('First Name').required(),
    last: yup.string().label('Last Name').required(),
  }),
  emailAddress: yup.string().label('Email').email().required(),
  phoneNumber: yup.string().label('Phone').required(),
});

const contactSchema2 = yup.object({
  title: yup.string().nullable(),
  name: yup.object({
    first: yup.string().label('First Name').nullable(),
    last: yup.string().label('Last Name').nullable(),
  }),
  emailAddress: yup.string().label('Email').email().nullable(),
  phoneNumber: yup.string().label('Phone').nullable(),
});
const grantSchema = yup.object({
  enabled: yup.bool().nullable(),
  description: yup.string().nullable(),
  amount: yup.string().nullable(),
  title: yup.string().nullable(),
});
const socialSchema = yup.object({
  charityNavigator: yup.string().nullable(),
  facebook: yup.string().nullable(),
  guideStar: yup.string().nullable(),
  instagram: yup.string().nullable(),
  linkedIn: yup.string().nullable(),
  tikTok: yup.string().nullable(),
  twitter: yup.string().nullable(),
  youTube: yup.string().nullable(),
});
const addressSchema = yup.object({
  city: yup.string().label('City').required().defined(),
  line1: yup.string().label('Line 1').required().defined(),
  line2: yup.string().nullable(),
  state: yup.string().label('State').required().defined(),
  zipcode: yup.string().label('Zipcode').required().defined(),
});

export const agencySchema = yup.object({
  legalName: yup.string().label('Legal Name').required(),
  displayName: yup.string().label('Display Name').required(),
  description: yup.string().label('Description').required(),
  information: yup.string().label('Information').required(),
  isDonor: yup.bool(),
  isFederatedFund: yup.bool(),
  matchingGrant: grantSchema.nullable(),
  mailingAddress: addressSchema,
  social: socialSchema,
  andarId: yup.string().nullable(),
  bookId: yup.string().nullable(),
  taxId: yup.string().required(),
  vanityUrl: yup.string().nullable(),
  websiteUrl: yup.string().nullable(),
  primaryContact: contactSchema,
  secondaryContact: contactSchema2,
  primaryCategory: yup.string().label('Primary Category').required(),
  secondaryCategory: yup.string().nullable(),
  internalComments: yup.string().label('Internal Comments').nullable(),
  eftReceived: yup.bool(),
});

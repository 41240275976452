import React from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormHelperText,
  Container,
} from '@mui/material';
import { UserRole, UsersAddUserForm, UsersAddUserResult, addUser } from '../../api/agency-api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, TextInput } from '../../shared/forms';
import { PhoneNumberMask } from '../../shared/forms/fields/InputMasks';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { BannerPlain } from '../../shared/components/BannerPlain';

export const AddUserPage: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const onSubmit = (form: UsersAddUserForm) => {
    addUser(form)
      .then(({ user }: UsersAddUserResult) => {
        enqueueSnackbar('User created successfully', { variant: 'success' });
        navigate(`/admin/users/${user?.id}`);
      })
      .catch((error: any) => {
        if (error.response.data.detail) {
          enqueueSnackbar(error.response.data.detail, { variant: 'error' });
        } else {
          enqueueSnackbar('Error creating user', { variant: 'error' });
        }
      });
  };
  return (
    <Form<UsersAddUserForm>
      onSubmit={onSubmit}
      defaultValues={{
        email: '',
        phoneNumber: '',
        name: { first: '', last: '' },
        address: { line1: '', line2: '', city: '', state: '', zipcode: '' },
        userRole: undefined,
      }}
      resolver={yupResolver(schema)}
    >
      <Box mb={2}>
        <BannerPlain
          bannerBGColor="primary.light"
          align="end"
          justify="center"
          height="320px"
          tabletHeight="300px"
          mobileHeight="250px"
          padding={2}
        >
          <Typography variant="h1" color="common.white">
            Add User
          </Typography>
        </BannerPlain>
        <Container sx={{ padding: '1rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" color="primary.main">
                General
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="name.first" variant="outlined" label="First Name" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="name.last" variant="outlined" label="Last Name" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="email" variant="outlined" label="Email" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                InputProps={{ inputComponent: PhoneNumberMask as any }}
                name="phoneNumber"
                variant="outlined"
                label="Phone Number (optional)"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="address.line1" variant="outlined" label="Address Line 1" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="address.line2" variant="outlined" label="Addres Line 2 (optional)" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="address.city" variant="outlined" label="City" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="address.state" variant="outlined" label="State" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="address.zipcode" variant="outlined" label="Zipcode" />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h2" color="primary.main">
              Roles
            </Typography>
            <FormControl>
              <Controller
                rules={{ required: true }}
                name="userRole"
                render={({ field }) => (
                  <>
                    <RadioGroup {...field}>
                      {Object.values(UserRole).map(x => (
                        <FormControlLabel
                          control={<Radio />}
                          label={x}
                          value={x}
                          sx={{
                            marginBottom: '.5rem',
                            width: { xs: '100%', xl: '32%' },
                            '& .MuiTypography-body1': { fontSize: '1rem', color: 'common.black' },
                          }}
                        />
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      name="userRole"
                      render={data => <FormHelperText error>{data.message}</FormHelperText>}
                    />
                  </>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              sx={{ backgroundColor: 'grey.200', width: '100%' }}
              p={2}
              spacing={2}
            >
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button onClick={() => navigate('/admin/dashboard')}>Cancel</Button>
            </Stack>
          </Grid>
        </Container>
      </Box>
    </Form>
  );
};

const schema = yup.object({
  email: yup.string().label('Email').email().required(),
  phoneNumber: yup.string().label('Phone Number').nullable(),
  name: yup.object({
    first: yup.string().label('First Name').required(),
    last: yup.string().label('Last Name').required(),
  }),
  address: yup.object({
    city: yup.string().label('City').required(),
    state: yup.string().label('State').required(),
    line1: yup.string().label('Address Line 1').required(),
    line2: yup.string().label('Address Line 2').nullable(),
    zipcode: yup.string().label('Zipcode').required(),
  }),
  userRole: yup
    .mixed<UserRole>()
    .oneOf(Object.values(UserRole))
    .required('User Role is a required field'),
});

/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  DonationsListAgencyDonationsDonationList,
  DonationsListDonationsDonationList,
  DonationsTallyDonationsResult,
  ExportAgencyDonationsParams,
  ExportDonationsParams,
  ListAgencyDonationsParams,
  ListDonationsParams,
} from '.././models';
import { apiRequest } from '.././client';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const listDonations = (
  eventId: string,
  params?: ListDonationsParams,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<DonationsListDonationsDonationList>(
    { url: `/Donations/events/${eventId}`, method: 'GET', params, signal },
    options
  );
};

export const getListDonationsQueryKey = (eventId: string, params?: ListDonationsParams) => {
  return [`/Donations/events/${eventId}`, ...(params ? [params] : [])] as const;
};

export const getListDonationsQueryOptions = <
  TData = Awaited<ReturnType<typeof listDonations>>,
  TError = unknown
>(
  eventId: string,
  params?: ListDonationsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listDonations>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDonationsQueryKey(eventId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDonations>>> = ({ signal }) =>
    listDonations(eventId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!eventId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listDonations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListDonationsQueryResult = NonNullable<Awaited<ReturnType<typeof listDonations>>>;
export type ListDonationsQueryError = unknown;

export const useListDonations = <
  TData = Awaited<ReturnType<typeof listDonations>>,
  TError = unknown
>(
  eventId: string,
  params?: ListDonationsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listDonations>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListDonationsQueryOptions(eventId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listAgencyDonations = (
  eventId: string,
  agencyId: string,
  params?: ListAgencyDonationsParams,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<DonationsListAgencyDonationsDonationList>(
    { url: `/Donations/events/${eventId}/agencies/${agencyId}`, method: 'GET', params, signal },
    options
  );
};

export const getListAgencyDonationsQueryKey = (
  eventId: string,
  agencyId: string,
  params?: ListAgencyDonationsParams
) => {
  return [
    `/Donations/events/${eventId}/agencies/${agencyId}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListAgencyDonationsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAgencyDonations>>,
  TError = unknown
>(
  eventId: string,
  agencyId: string,
  params?: ListAgencyDonationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listAgencyDonations>>, TError, TData>
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListAgencyDonationsQueryKey(eventId, agencyId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAgencyDonations>>> = ({ signal }) =>
    listAgencyDonations(eventId, agencyId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(eventId && agencyId),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof listAgencyDonations>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ListAgencyDonationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAgencyDonations>>
>;
export type ListAgencyDonationsQueryError = unknown;

export const useListAgencyDonations = <
  TData = Awaited<ReturnType<typeof listAgencyDonations>>,
  TError = unknown
>(
  eventId: string,
  agencyId: string,
  params?: ListAgencyDonationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listAgencyDonations>>, TError, TData>
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAgencyDonationsQueryOptions(eventId, agencyId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const exportDonations = (
  eventId: string,
  params?: ExportDonationsParams,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<Blob>(
    {
      url: `/Donations/events/${eventId}/export`,
      method: 'GET',
      params,
      responseType: 'blob',
      signal,
    },
    options
  );
};

export const getExportDonationsQueryKey = (eventId: string, params?: ExportDonationsParams) => {
  return [`/Donations/events/${eventId}/export`, ...(params ? [params] : [])] as const;
};

export const getExportDonationsQueryOptions = <
  TData = Awaited<ReturnType<typeof exportDonations>>,
  TError = unknown
>(
  eventId: string,
  params?: ExportDonationsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof exportDonations>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExportDonationsQueryKey(eventId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exportDonations>>> = ({ signal }) =>
    exportDonations(eventId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!eventId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exportDonations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExportDonationsQueryResult = NonNullable<Awaited<ReturnType<typeof exportDonations>>>;
export type ExportDonationsQueryError = unknown;

export const useExportDonations = <
  TData = Awaited<ReturnType<typeof exportDonations>>,
  TError = unknown
>(
  eventId: string,
  params?: ExportDonationsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof exportDonations>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExportDonationsQueryOptions(eventId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const exportAgencyDonations = (
  eventId: string,
  agencyId: string,
  params?: ExportAgencyDonationsParams,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<Blob>(
    {
      url: `/Donations/events/${eventId}/agencies/${agencyId}/export`,
      method: 'GET',
      params,
      responseType: 'blob',
      signal,
    },
    options
  );
};

export const getExportAgencyDonationsQueryKey = (
  eventId: string,
  agencyId: string,
  params?: ExportAgencyDonationsParams
) => {
  return [
    `/Donations/events/${eventId}/agencies/${agencyId}/export`,
    ...(params ? [params] : []),
  ] as const;
};

export const getExportAgencyDonationsQueryOptions = <
  TData = Awaited<ReturnType<typeof exportAgencyDonations>>,
  TError = unknown
>(
  eventId: string,
  agencyId: string,
  params?: ExportAgencyDonationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exportAgencyDonations>>, TError, TData>
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getExportAgencyDonationsQueryKey(eventId, agencyId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exportAgencyDonations>>> = ({ signal }) =>
    exportAgencyDonations(eventId, agencyId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(eventId && agencyId),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof exportAgencyDonations>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ExportAgencyDonationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof exportAgencyDonations>>
>;
export type ExportAgencyDonationsQueryError = unknown;

export const useExportAgencyDonations = <
  TData = Awaited<ReturnType<typeof exportAgencyDonations>>,
  TError = unknown
>(
  eventId: string,
  agencyId: string,
  params?: ExportAgencyDonationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof exportAgencyDonations>>, TError, TData>
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExportAgencyDonationsQueryOptions(eventId, agencyId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const tallyDonations = (
  eventId: string,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<DonationsTallyDonationsResult>(
    { url: `/Donations/events/${eventId}/tally`, method: 'GET', signal },
    options
  );
};

export const getTallyDonationsQueryKey = (eventId: string) => {
  return [`/Donations/events/${eventId}/tally`] as const;
};

export const getTallyDonationsQueryOptions = <
  TData = Awaited<ReturnType<typeof tallyDonations>>,
  TError = unknown
>(
  eventId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof tallyDonations>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTallyDonationsQueryKey(eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tallyDonations>>> = ({ signal }) =>
    tallyDonations(eventId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!eventId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tallyDonations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TallyDonationsQueryResult = NonNullable<Awaited<ReturnType<typeof tallyDonations>>>;
export type TallyDonationsQueryError = unknown;

export const useTallyDonations = <
  TData = Awaited<ReturnType<typeof tallyDonations>>,
  TError = unknown
>(
  eventId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof tallyDonations>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTallyDonationsQueryOptions(eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

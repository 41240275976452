import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useNavigationQuery } from '../../queries';
import { NavigationStructure } from './NavigationStructure';
import { withBoundary } from '../withBoundary';

const Context = createContext<NavigationStructure>({} as NavigationStructure);

export function useNavigationStructure() {
  return useContext(Context);
}

const Provider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data } = useNavigationQuery();

  const structure = useMemo(() => new NavigationStructure(data), [data]);

  return <Context.Provider value={structure}>{children}</Context.Provider>;
};

export const NavigationStructureProvider = withBoundary(Provider);

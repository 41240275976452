import React, { PropsWithChildren, useMemo } from 'react';
import { FieldValues, FormProvider, UseFormProps, useForm, SubmitHandler } from 'react-hook-form';

interface Props<TFieldValues extends FieldValues = FieldValues, TContext extends object = object>
  extends PropsWithChildren,
    UseFormProps<TFieldValues, TContext> {
  onSubmit: SubmitHandler<TFieldValues>;
}

export function Form<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>({ onSubmit, children, ...props }: Props<TFieldValues, TContext>) {
  const form = useForm<TFieldValues, TContext>(props);

  const submit = useMemo(() => form.handleSubmit(values => onSubmit(values)), [form, onSubmit]);

  return (
    <FormProvider {...form}>
      <form onSubmit={submit}>{children}</form>
    </FormProvider>
  );
}
